<template>
    <v-container>
        <v-card elevation="0">
            <v-card-title class="black white--text">Cartoons</v-card-title>
            <v-card-text class="pt-3">
                <v-row>
                    <v-col cols="6" md="6" lg="3"
                           v-for="(item, i) in cartoons"
                           :key="i" v-viewer="options">
                        <div class="comic-outline">
                            <img :src="cartoonThumbSrc + item.src" :data-src="cartoonSrc + item.src" :alt="item.title"
                                 width="100%" class="fill-height"/>
                            <div class="text-center body-1 ink-inc title">{{ item.title }}</div>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: "Cartoon",
    data: () => ({
        options: {
            movable: true,
            navbar: 0,
            toolbar: 0,
            url: 'data-src'
        },
        model: 0,
        cartoonSrc: '/images/cartoons/',
        cartoonThumbSrc: '/images/cartoons/thumbnail/',
        cartoons: [
            {src: "cartoon003.jpg", title: "Amonk Us Cover"},
            {src: "cartoon004.jpg", title: "Amonk Us Comic"},
            {src: "cartoon001.jpg", title: "Circle Monkey"},
            {src: "cartoon002.jpg", title: "Circle Monkey - Shouting"},
        ],
    }),
};
</script>

<style>
.comic-outline {
    border: 1px solid grey;
    margin: 4px;
    position: relative;
}

.comic-outline .title {
    /*position: absolute;*/
    top: 0;
    left: 0;
    right: 0;
}


</style>
